const $_ALTENARCONFIG = {};

const widget = {
  OddBox: {
    variant: 0,
    spacing: 4,
    borderRadius: 2,
    borderWidth: 1,
    borderColor: 'rgba(255, 255, 255, 0.1)',
    background: '#394557',
    paddingVertical: 10,
    paddingHorizontal: 12,
    oddFont: ['Roboto Condensed', 16, 'normal', 400, 'normal'],
    labelFont: ['Roboto Condensed', 16, 'normal', 400, 'normal'],
    oddColor: '#ffffff',
    labelColor: 'rgba(255, 255, 255, 0.6)',
    arrowUp: [24, 24, '#4caf50'],
    arrowDown: [24, 24, '#f44336'],
  },
  OddBoxSelected: {
    borderColor: '#fff',
    background: '#05207B',
    oddColor: '#FFFFFF',
    labelColor: '#FFFFFF',
  },
  MarketBox: {
    width: '100%',
    spacing: 4,
  },
  EventBox: {
    borderWidth: 1,
    borderColor: '#E9EBF7',
    borderStyle: 'solid',
    borderRadius: 0,
    background: '#FFFFFF',
    paddingVertical: 12,
    paddingHorizontal: 12,
    liveTimeFont: ['Roboto Condensed', 14, 'normal', 500, '21px'],
    dateTimeFont: ['Roboto Condensed', 12, 'normal', 600, '20px'],
    categoryChampionshipFont: ['Roboto Condensed', 12, 'normal', 600, '20px'],
    competitorFont: ['Roboto Condensed', 14, 'normal', 700, '21px'],
    scoreFont: ['Roboto Condensed', 14, 'normal', 700, '21px'],
    liveTimeColor: '#65697B',
    dateTimeColor: 'rgba(0, 0, 0, 0.87)',
    categoryChampionshipColor: 'rgba(0, 0, 0, 0.54)',
    competitorColor: 'rgba(0, 0, 0, 0.87)',
    scoreColor: 'rgba(0, 0, 0, 0.87)',
  },
  SportTab: {
    borderWidth: 0,
    borderColor: 'unset',
    borderStyle: 'none',
    borderRadius: 0,
    font: ['Roboto Condensed', 16, 'normal', 700, '14px'],
    background: '#fff',
    color: '#05207B',
    textTransform: 'capitalize',
    paddingHorizontal: 12,
    paddingVertical: 10,
    borderRadius: 0,
    spacing: 16,
    textTransform: 'uppercase',
  },
  SportTabSelected: {
    font: ['Roboto Condensed', 16, 'normal', 700, '14px'],
    indicatorColor: '#fff',
    borderColor: '#fff',
    color: '#fff',
    background: '#05207B',
    textTransform: 'uppercase',
  },
  TabbedTopSportEvents: {
    borderWidth: 1,
    borderColor: '#E9EBF7',
    borderStyle: 'solid',
    borderRadius: 0,
    paddingHorizontal: 16,
    paddingVertical: 16,
    background: '#fff',
    titleColor: '#05207B',
    titleFont: ['Roboto Condensed', 18, 'italic', 900, '40px'],
    titleTextTransform: 'uppercase',
  },
  EventList: {
    variant: 1,
    rowSpacing: 8,
    columnSpacing: 16,
  },
  LiveIndicator: {
    variant: 0,
    borderWidth: 0,
    borderColor: 'unset',
    borderStyle: 'none',
    font: ['Roboto Condensed', 12, 'normal', 700, '21px'],
    textTransform: 'uppercase',
    background: '#CC0034',
    color: '#fff',
    borderRadius: 0,
    paddingHorizontal: 3,
    paddingVertical: 1,
    clipPath: 'unset',
  },
};

let streaming = {
  LiveStreaming: {
    borderWidth: 0,
    borderColor: '#2A394E',
    background: '#273242',
    borderRadius: 2,
    paddingVertical: 8,
    paddingHorizontal: 8,
  },
  LiveStreamingHeader: {
    background: '#273242',
    paddingVertical: 4,
    paddingHorizontal: 20,
    font: ['Roboto', 0, 'normal', 500, '0px'],
  },
  LiveStreamingTabs: {
    background : 'transparent',
    paddingHorizontal: 8,
  },
  LiveStreamingTab:{
    background : 'transparent',
    color: '#ffffff',
    paddingVertical: 6,
    paddingHorizontal: 6,
    borderWidth: 1,
    borderColor: 'transparent',
    iconColor: '#ffffff',
    iconBackground: 'transparent',
  },
  LiveStreamingTabSelected:{
    background: '#162334',
    borderWidth: 1,
    borderColor: '#2A394E',
    color: '#ffffff',
  },
  OddBox: {
    variant: 0,
    spacing: 4,
    borderRadius: 2,
    borderWidth: 1,
    borderColor: 'rgba(255, 255, 255, 0.1)',
    background: '#182537',
    paddingVertical: 8,
    paddingHorizontal: 12,
    oddFont: ['Roboto', 15, 'normal', 400, 'normal'],
    labelFont: ['Roboto', 15, 'normal', 400, 'normal'],
    oddColor: '#ffffff',
    labelColor: 'rgba(255, 255, 255, 0.6)',
    arrowUp: [24, 24, '#4caf50'],
    arrowDown: [24, 24, '#f44336'],
  },
  OddBoxSelected: {
    borderColor: 'rgba(255, 255, 255, 0.10)',
    background: '#ECFD00',
    oddColor: '#0B1222',
    labelColor: 'rgba(11, 18, 34, 0.6)',
  },
  LiveStreamingEvent: {
    background: '#273242',
    borderRadius: 2,
    borderWidth: 1,
    borderColor: '#394557',
    paddingVertical: 6,
    paddingHorizontal: 8,
    liveTimeFont: ['Roboto Condensed', 12, 'normal', 700, 'normal'],
    liveTimeColor: "#FFFFFF",
    competitorFont: ['Roboto Condensed', 14, 'normal', 400, 'normal'],
    competitorColor: "#A5AFC0",
    scoreFont: ['Roboto Condensed', 12, 'normal', 400, 'normal'],
    scoreColor: "#A5AFC0",
  },
  LiveStreamingEventSelected: {
    background: '#182537',
    liveTimeColor: "#ECFD00",
    competitorColor: "#EFF5FD",
    scoreColor: "#FFFFFF",
    borderColor: '#394557',
  }
}

const carousel = {
  OddBox: {
    variant: 0,
    spacing: 4,
    borderRadius: 2,
    borderWidth: 1,
    borderColor: 'rgba(255, 255, 255, 0.1)',
    background: '#394557',
    paddingVertical: 12,
    paddingHorizontal: 8,
    oddFont: ['Roboto Condensed', 16, 'normal', 400, 'normal'],
    labelFont: ['Roboto Condensed', 16, 'normal', 400, 'normal'],
    oddColor: '#ffffff',
    labelColor: 'rgba(255, 255, 255, 0.6)',
    arrowUp: [24, 24, '#37FB3F'],
    arrowDown: [24, 24, '#f44336'],
  },
  OddBoxSelected: {
    borderColor: 'rgba(255, 255, 255, 0.10)',
    background: '#ECFD00',
    oddColor: '#0B1222',
    labelColor: 'rgba(11, 18, 34, 0.6)',
  },
  BannerEventBox: {
    variant: 0,
    width: 272,
    borderWidth: 1,
    borderColor: '#2A394E',
    borderRadius: 2,
    background: '#273242',
    paddingVertical: 12,
    paddingHorizontal: 12,
    timeFont: ['Roboto Condensed', 14, 'normal', 700, '20px'],
    dateFont: ['Roboto Condensed', 14, 'normal', 400, '20px'],
    championshipFont: ['Roboto Condensed', 16, 'normal', 400, '20px'],
    competitorFont: ['Roboto Condensed', 14, 'normal', 400, '16px'],
    messageBodyFont: ['Roboto Condensed', 16, 'normal', 500, '24px'],
    messageHeaderFont: ['Roboto Condensed', 16, 'normal', 500, '24px'],
    timeColor: '#FFFFFF',
    dateColor: 'rgba(255, 255, 255, 0.6)',
    championshipColor: 'rgba(255, 255, 255, 0.6)',
    competitorColor: '#FFFFFF',
    messageHeaderColor: '#FFFFFF',
    messageBodyColor: '#FFFFFF',
    dividerColor: 'rgba(0, 0, 0, 0.12)',
    dividerWidth: 1,
    dividerHeight: '85%',
    scoreColor: 'rgba(255, 255, 255)',
    liveTimeColor: 'rgba(255, 255, 255)',
  },
  MarketBox: {
    font: ['Roboto Condensed', 14, 'normal', 700, '16px'],
    color: '#ffffff',
    spacing: 6,
  },
  CompetitorLogo: {
    height: 64,
    width: 64,
    background: 'transparent',
    borderRadius: '0',
    borderColor: 'unset',
    borderWidth: 0,
    offset: -15,
    placeholderColor: 'rgba(77, 77, 77, 0.2)',
  },
  EventsCarousel: {
    borderWidth: 0,
    borderColor: 'unset',
    borderRadius: 0,
    width: 'unset',
    paddingHorizontal: 0,
    paddingVertical: 0,
    spacing: 12,
  },
  EventsCarouselControls: {
    borderWidth: 0,
    borderColor: '#2A394E',
    borderRadius: 2,
    dotBackground: 'rgba(255, 255, 255, 0.87)',
    selectedDotBackground: '#273242',
    background: 'rgb(57, 69, 87)',
    color: 'rgba(255, 255, 255, 0.87)',
    height: 0,
    width: 0,
  },
  EventsCarouselControlsHovered: {
    background: '#576477',
    color: '#ffffff',
  },
  LiveIndicator: {
    variant: 0,
    borderWidth: 0,
    borderColor: 'unset',
    borderStyle: 'none',
    font: ['Roboto Condensed', 12, 'normal', 700, '21px'],
    textTransform: 'uppercase',
    background: '#CC0034',
    color: '#fff',
    borderRadius: 0,
    paddingHorizontal: 3,
    paddingVertical: 1,
    clipPath: 'unset',
  },
};

$_ALTENARCONFIG.widget = {
  tokens: {
    liveNow: widget,
    highlights: widget,
  },
  carousel,
  streaming,
};

export { $_ALTENARCONFIG };
