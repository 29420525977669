const aliases = {
  home: {
    page: true,
    name: 'app.root.l1.l8.home',
  },
  onboarding: {
    page: false,
    name: 'onboarding',
  },
  deposit: {
    page: false,
    name: 'cashbox',
    private: true,
  },
  withdrawal: {
    page: false,
    name: 'cashbox',
    private: true,
    content: { cashboxState: { tab: 'withdrawal' } },
  },
  login: {
    page: false,
    name: 'login',
  },
  registration: {
    page: false,
    name: 'registration',
  },
  fastRegistration: {
    page: true,
    name: 'app.root.l1.paynplay',
  },
  promo: {
    page: true,
    name: 'app.root.l1.l8.promo.list',
    params: {
      tab: 'sport',
    },
  },
  promos: {
    page: true,
    name: 'app.root.l1.l8.promo.list',
    params: {
      tab: 'sport',
    },
  },
  promotions: {
    page: true,
    name: 'app.root.l1.l8.promo.list',
    params: {
      tab: 'sport',
    },
  },
  promoItem: {
    page: true,
    name: 'app.root.l1.l8.promo.item',
  },
  realGame: {
    page: true,
    name: 'app.root.real',
  },
  demoGame: {
    page: true,
    name: 'app.root.demo',
  },
  tournaments: {
    page: true,
    name: 'app.root.l1.l8.tournaments',
  },
  profile: {
    page: true,
    name: 'app.root.l1.profile',
    private: true,
  },
  bonuses: {
    page: true,
    name: 'app.root.l1.bonus.active',
    private: true,
  },
  paynplay: {
    page: true,
    name: 'app.root.l1.paynplay',
  },
  successBonusActivation: {
    page: true,
    name: 'app.root.l1.bonus.active',
  },
  gameHall: {
    page: true,
    name: 'app.root.l1.l8.l2.l3.gamehall',
  },
  bet: {
    page: true,
    name: 'app.root.l1.l8.sport.live',
  },
  collection: {
    page: true,
    name: 'app.root.l1.l8.collections.all',
  },
  sport: {
    page: true,
    name: 'app.root.l1.l8.sport.prelive',
  },
  live: {
    page: true,
    name: 'app.root.l1.l8.l2.l3.gamehall',
    params: {
      collection: 'live-casino',
    },
  },
  games: {
    page: true,
    name: 'app.root.l1.l8.l2.l3.gamehall',
  },
  notFound: {
    page: true,
    name: 'app.root.l1.l8.404',
  },
  missedData: {
    page: true,
    name: 'app.root.missedData',
  },
  balanceHistory: {
    page: true,
    name: 'app.root.l1.history',
    private: true,
  },
  terms: {
    page: true,
    name: 'app.root.l1.l5.terms',
  },

  bigbassbonanza: {
    page: true,
    name: 'app.root.real',
    params: {
      name: 'big-bass-bonanza',
    },
  },
  bigbassbonanzademo: {
    page: true,
    name: 'app.root.demo',
    params: {
      name: 'big-bass-bonanza',
    },
  },
  // promo steps aliases
  football: {
    page: true,
    name: 'app.root.l1.l8.sport.prelive',
    params: {
      sportids: '66',
    },
  },
  soccer: {
    page: true,
    name: 'app.root.l1.l8.sport.prelive',
    params: {
      sportids: '66',
    },
  },
  tenis: {
    page: true,
    name: 'app.root.l1.l8.sport.prelive',
    params: {
      sportids: '68',
    },
  },
  tennis: {
    page: true,
    name: 'app.root.l1.l8.sport.prelive',
    params: {
      sportids: '68',
    },
  },
  tabletenis: {
    page: true,
    name: 'app.root.l1.l8.sport.prelive',
    params: {
      sportids: '77',
    },
  },
  basketball: {
    page: true,
    name: 'app.root.l1.l8.sport.prelive',
    params: {
      sportids: '67',
    },
  },
  icehockey: {
    page: true,
    name: 'app.root.l1.l8.sport.prelive',
    params: {
      sportids: '70',
    },
  },
  esports: {
    page: true,
    name: 'app.root.l1.l8.sport.prelive',
    params: {
      sportids: '145',
    },
  },
  formula1: {
    page: true,
    name: 'app.root.l1.l8.sport.prelive',
    params: {
      sportids: '103',
    },
  },
  mma: {
    page: true,
    name: 'app.root.l1.l8.sport.prelive',
    params: {
      sportids: '84',
    },
  },
  cricket: {
    page: true,
    name: 'app.root.l1.l8.sport.prelive',
    params: {
      sportids: '149',
    },
  },
  americanfootball: {
    page: true,
    name: 'app.root.l1.l8.sport.prelive',
    params: {
      sportids: '75',
    },
  },
  baseball: {
    page: true,
    name: 'app.root.l1.l8.sport.prelive',
    params: {
      sportids: '76',
    },
  },
  euro2024: {
    page: true,
    name: 'app.root.l1.l8.sport.prelive',
    params: {
      champids: '3031',
    },
  },
  aviator: {
    page: true,
    name: 'app.root.real',
    params: {
      name: 'aviator',
    },
  },
  leftlink1: {
    page: true,
    name: 'app.root.l1.l8.cup',
  },
  leftlink2: {
    page: true,
    name: 'app.root.l1.l8.collections.all',
  },
  leftlink3: {
    page: true,
    name: 'app.root.l1.achievement',
  },
  leftlink4: {
    page: true,
    name: 'app.root.l1.l8.promo.list',
    params: {
      tab: 'sport',
    },
  },
  rightlink1: {
    page: true,
    name: 'app.root.l1.l8.l2.l3.gamehall',
    params: {
      collection: 'live-casino',
    },
  },
  rightlink2: {
    page: true,
    name: 'app.root.l1.l8.l2.l3.games',
    params: {
      category: 'slots',
    },
  },
  rightlink3: {
    page: true,
    name: 'app.root.l1.l8.l2.l3.games',
    params: {
      category: 'popular',
    },
  },
  rightlink4: {
    page: true,
    name: 'app.root.l1.l8.l2.l3.games',
    params: {
      category: 'top',
    },
  },
  // horse racing aliases use
  HoRacing: {
    page: true,
    name: 'app.root.l1.l8.horse_racing.horse_racing',
  },
  HaRacing: {
    page: true,
    name: 'app.root.l1.l8.horse_racing.harness',
  },
  GreyRacing: {
    page: true,
    name: 'app.root.l1.l8.horse_racing.greyhound',
  },
  NextToJump: {
    page: true,
    name: 'app.root.l1.l8.horse_racing.next-to-jump',
  },
  gameProvider: {
    page: true,
    name: 'app.root.l1.l8.l2.l4.provider',
  },
  // <MACHINES>
  bonus_crab: {
    page: true,
    name: 'app.root.claw_games.gamepage',
    params: {
      name: 'cash-crab',
    },
  },
  cash_crab: {
    page: true,
    name: 'app.root.claw_games.gamepage',
    params: {
      name: 'cash_crab',
    },
  },
  claw: {
    page: true,
    name: 'app.root.claw_games.gamepage',
  },
  // <MACHINES>
  oktoberfest: {
    page: true,
    name: 'app.root.claw_games.gamepage',
    params: {
      name: 'oktoberfest',
    },
  },
  evolution: {
    page: true,
    name: 'app.root.l1.l8.l2.l4.provider',
    params: {
      name: 'evolution',
    },
  },
  pragmatic: {
    page: true,
    name: 'app.root.l1.l8.l2.l4.provider',
    params: {
      name: 'pragmatic',
    },
  },
  playngo: {
    page: true,
    name: 'app.root.l1.l8.l2.l4.provider',
    params: {
      name: 'playngo',
    },
  },
  promo_astropay: {
    page: true,
    name: 'app.root.l1.l8.promo.item',
    params: {
      name: '40-extra-com-astropay',
    },
  },
  threeoaks: {
    page: true,
    name: 'app.root.l1.l8.l2.l4.provider',
    params: {
      name: 'threeoaks',
    },
  },
  spinomenal: {
    page: true,
    name: 'app.root.l1.l8.l2.l4.provider',
    params: {
      name: 'spinomenal',
    },
  },
  ela: {
    page: true,
    name: 'app.root.l1.l8.l2.l4.provider',
    params: {
      name: 'ela',
    },
  },
  relax: {
    page: true,
    name: 'app.root.l1.l8.l2.l4.provider',
    params: {
      name: 'relax',
    },
  },
  skywind: {
    page: true,
    name: 'app.root.l1.l8.l2.l4.provider',
    params: {
      name: 'skywind',
    },
  },
  netent: {
    page: true,
    name: 'app.root.l1.l8.l2.l4.provider',
    params: {
      name: 'netent',
    },
  },
  pushgaming: {
    page: true,
    name: 'app.root.l1.l8.l2.l4.provider',
    params: {
      name: 'pushgaming',
    },
  },
  playtech: {
    page: true,
    name: 'app.root.l1.l8.l2.l4.provider',
    params: {
      name: 'playtech',
    },
  },
  quickspin: {
    page: true,
    name: 'app.root.l1.l8.l2.l4.provider',
    params: {
      name: 'quickspin',
    },
  },
  redtiger: {
    page: true,
    name: 'app.root.l1.l8.l2.l4.provider',
    params: {
      name: 'redtiger',
    },
  },
  seasonTournament: {
    page: true,
    private: true,
    name: 'app.root.l1.l8.sport.prelive',
    params: {
      champids: '3013',
    },
  },
  summer: {
    page: true,
    name: 'app.root.summer',
    private: true,
  },
  summerGame: {
    page: true,
    name: 'app.root.summer-game',
  },
  christmasPromotion: {
    page: true,
    name: 'app.root.l1.l8.promo.item',
    params: {
      name: 'million-frostival-spins',
    },
  },
  christmasPromotionRaffle: {
    page: true,
    name: 'app.root.l1.l8.promo.item',
    params: {
      name: 'frostival-prizes',
    },
  },
  australianOpen: {
    page: true,
    name: 'app.root.l1.l8.sport.prelive',
    params: {
      sportids: '68',
    },
  },
  footballlive: {
    page: true,
    name: 'app.root.l1.l8.sport.prelive',
    params: {
      sportids: '66',
    },
  },
  tennislive: {
    page: true,
    name: 'app.root.l1.l8.sport.prelive',
    params: {
      sportids: '68',
    },
  },
  basketballlive: {
    page: true,
    name: 'app.root.l1.l8.sport.prelive',
    params: {
      sportids: '67',
    },
  },
  icehockeylive: {
    page: true,
    name: 'app.root.l1.l8.sport.prelive',
    params: {
      sportids: '70',
    },
  },
  tabletennislive: {
    page: true,
    name: 'app.root.l1.l8.sport.prelive',
    params: {
      sportids: '77',
    },
  },
  virtual: {
    page: true,
    name: 'app.root.l1.l8.sport.virtual',
  },
  footballvr: {
    page: true,
    name: 'app.root.l1.l8.sport.virtual',
    params: {
      sportids: 'kiron_football',
    },
  },
  ebasketball: {
    page: true,
    name: 'app.root.l1.l8.sport.live',
    params: {
      sportids: '147',
    },
  },
  efootball: {
    page: true,
    name: 'app.root.l1.l8.sport.live',
    params: {
      sportids: '66',
    },
  },
  handball: {
    page: true,
    name: 'app.root.l1.l8.sport.live',
    params: {
      sportids: '73',
    },
  },
  tennisvr: {
    page: true,
    name: 'app.root.l1.l8.sport.virtual',
    params: {
      sportids: 'vti',
    },
  },
  esport: {
    page: true,
    name: 'app.root.l1.l8.sport.prelive',
    params: {
      sportids: '145',
    },
  },
  edogracing: {
    page: true,
    name: 'app.root.l1.l8.sport.virtual',
    params: {
      sportids: 'kiron_dogs',
    },
  },
  ehorseracing: {
    page: true,
    name: 'app.root.l1.l8.sport.virtual',
    params: {
      sportids: 'kiron_horses',
    },
  },
  emotoracing: {
    page: true,
    name: 'app.root.l1.l8.sport.virtual',
    params: {
      sportids: 'kiron_motor_racing',
    },
  },
  // tysonPromo: {
  //   page: true,
  //   name: 'app.root.l1.l8.tyson_ambassador',
  // },
  cup: {
    page: true,
    name: 'app.root.l1.l8.cup',
  },
};

export { aliases };
