import '../../../service/domain/collection.js'
(function () {
  controller.$inject = ['$scope', 'collection'];

  function controller($scope, _collection) {
    $scope.list = [];

    this.$onChanges = () => {
      const count = this.count | 1000;
      $scope.preloader = true;
      _collection
        .list({ count })
        .then((a) => {
          $scope.list = a.result;
        })
        .catch((e) => {})
        .finally(() => {
          $scope.preloader = false;
        });
    };
  }

  new Controller('collectionList', controller, {
    count: '<',
  });
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanCollectionList${i}`, 'collectionList');
  });
})();
