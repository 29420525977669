import config from '../../app/service/configs/config';
(function () {
  'use strict';
  const directive = { name: 'rabonaSplitPhone' };
  controller.$inject = ['config'];

  function controller(_config) {
    function link(scope, element) {
      scope.phoneCode = scope.countries[0].prefix;
      scope.userPhone = '';
      const userPhoneInput = element[0];

      onInit();

      function onInit() {
        const preselectedCountry = scope.countries.find(({ code }) => code === _config.system_info.country.code);
        preSelectPhoneCode(preselectedCountry);
      }

      function preSelectPhoneCode(country) {
        if (!country) return;
        scope.phoneCode = country.prefix;
      }

      userPhoneInput.addEventListener('blur', () => {
        if (scope.userPhone.length > 0) scope.regFour.phone.$setTouched();
      });

      scope.$watchGroup(['phoneCode', 'userPhone'], (newVal) => {
        scope.formData.phone = `${scope.phoneCode}${scope.userPhone}`;
        scope.userPhone = newVal[1] ? scope.userPhone.replace(/[^0-9]/g, '') : '';
      });

      scope.$watch('country', (newCountry) => preSelectPhoneCode(newCountry));
    }

    return {
      restrict: 'A',
      link: link,
    };
  }

  app.directive(directive.name, controller);
})();
