import '../../service/domain/user.js';
import '../../service/rx/bonus$.js';
(function () {
  'use strict';

  const component = { name: 'lindaBonusListMobRabona' };

  controller.$inject = ['$scope', '$state', 'user', 'bonus$'];

  function controller($scope, $state, _user, _bonus$) {
    $scope.collection = [];
    $scope.type = $state.params.bonusType;

    bonusCollection();

    _bonus$.subscribe(() => {
      bonusCollection();
    });

    function bonusCollection() {
      switch ($scope.type) {
        case 'active':
          $scope.collection = _user.bonus.filter((item) => item.status === 'active');
          break;
        case 'casino':
          $scope.collection = _user.casinoBonus.filter((item) => item.status !== 'active');
          break;
        case 'sport':
          $scope.collection = _user.sportBonus.filter((item) => item.status !== 'active');
          break;
      }
    }
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
